// Takes inuit error and returns error key and appropriate error message for user viewing
export const handleError = (error) => {
    if (error.detail == 'card.expyear') {
        return {
            key: 'expiration',
            message: 'Expiration year needs to be in the 2020 to 2045 range'
        }
    } else if (error.detail == 'card.expmonth') {
        return {
            key: 'expiration',
            message: 'Expiration month needs to be in the format ## (e.g. 04) and in the range 01-12'
        }
    } else if (error.detail == "card.ExpirationMonth/ExpirationYear") {
        return {
            key: 'expiration',
            message: error.moreInfo
        }
    } else if (error.detail == 'card.cvc') {
        return {
            key: 'cvc',
            message: error.moreInfo
        }
    } else if (error.detail == 'card.number') {
        return {
            key: 'cardNumber',
            message: 'Card number is invalid'
        }
    } else if (error.detail == 'address.city') {
        return {
            key: 'city',
            message: error.moreInfo
        }
    } else if (error.detail == 'address.street_address') {
        return {
            key: "streetAddress",
            message: error.moreInfo
        }
    } else if (error.detail == 'address.region') {
        return {
            key: 'region',
            message: error.moreInfo
        }
    } else if (error.detail == 'address.country') {
        return {
            key: 'country',
            message: error.moreInfo
        }
    }
}