import React from 'react'
import MinimalNavbar from '../components/website_components/MinimalNavbar/MinimalNavbar'
import "../styles/global.css"

const CheckoutLayout = ({ children }) => (
  <>
    <MinimalNavbar />
    <div className="bcp page">
      {children}
    </div>
  </>
);

export default CheckoutLayout;