import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import ClientInfoForm from './ClientInfoForm/ClientInfoForm'
import PaymentForm from './PaymentForm/PaymentForm'
import PayConfirmation from './PayConfirmation/PayConfirmation'
import ItemPreview from './ItemPreview/ItemPreview'
import styles from './Checkout.module.css'
import ItemImage from '../../images/course.jpg'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import { getCourse, getSession, getAdvisingType } from '../../api/api'

const VERTICAL = 1024

const Checkout = ( props ) => {
    const [item, setItem] = useState(null)
    const [vertical, setVertical] = useState(false)
    const [stage, setStage] = useState('information')
    const [approved, setApproved] = useState({
        information: false,
        paymentInfo: false,
        confirmation: false
    })
    const [clientInfo, setClientInfo] = useState(null)
    const [paymentInfo, setPaymentInfo] = useState(null)
    const [checkoutType, setCheckoutType] = useState('course')
    const [loaded, setLoaded] = useState(false)
    const [show, setShow] = useState(false)
    const [serverErrors, setServerErrors] = useState(null)
    const [purchaseStatus, setPurchaseStatus] = useState('ready')

    useEffect(() => {
        // Initialize display mode (vertical)
        setDisplayVertical()

        // "location" contains state passed through navigate, user will be redirected away from checkout if state was not passed
        let locationData = props.location;

        // Check current path name, set item based on result
        if (props.path === '/course/:id') {
            setCheckoutType('course')

            getCourse(props?.id)
                .then(res => {
                    setLoaded(true)
                    setItem({ ...res.data })
                })
                .catch((err) => {
                    navigate('/checkout')
                })
        } else if (props.path === '/session/:id') {
            setCheckoutType('session')

            getSession(props?.id)
                .then(session => {
                    getAdvisingType(session.data.advisingType)
                        .then(type => {
                            setLoaded(true)
                            setItem({
                                ...type.data,
                                ...session.data,
                                selectedTimes: locationData.state.timeIds
                            })
                        })
                        .catch(err => {
                            console.log(err)
                            navigate('/checkout')
                        })
                })
                .catch((err)=> {
                    console.log(err)
                    navigate('/checkout')
                })
        } 
    }, [])

    // Scroll the user to the top of the page whenever the current checkout stage is changed (from client info to billing info for example)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [stage])

    useEffect(() => {
        if (serverErrors && purchaseStatus !== 'ready') {
            // Set purchase state to 'ready' if serverErrors is empty
            function checkForErrors() {
                let noErrors = true

                for (const key in serverErrors) {
                    if (serverErrors[key] !== undefined) {
                        noErrors = false
                    }
                }

                return noErrors
            }

            if (checkForErrors()) {
                setPurchaseStatus('ready')
            }
        }
    }, [serverErrors])

    useEffect(() => {
        window.addEventListener('resize', setDisplayVertical, { passive: true })

        return function cleanup() {
            window.removeEventListener('resize', setDisplayVertical, { passive: true })
        }
    })

    function setDisplayVertical(event) {
        if (window.innerWidth < VERTICAL) {
            setVertical(true)
        } else {
            setVertical(false)
        }
    }

    function generateItemPreview() {
        return (
            <ItemPreview
            item={{
                title: item?.name,
                image: ItemImage, // Placeholder
                description: item?.description,
                price: item?.price
            }} />
        )
    }

    // Clear a single error in the list of server errors
    function clearError(key) {
        if (serverErrors && serverErrors[key]) {
            let temp = { ...serverErrors }
            temp[key] = undefined
            setServerErrors(temp)
        }
    }

    if (loaded) {
        return (
            <>
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    {purchaseStatus === 'loading' && (
                        <>
                            <Modal.Body className={`d-flex align-items-center flex-column ${styles.modalBodySpinner}`}>
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                <div className={styles.textGeneral} style={{ marginTop: "20px" }}>Processing payment, please wait a moment...</div>
                            </Modal.Body>
                        </>
                    )}
                    {purchaseStatus === 'success' && (
                        <>
                            <Modal.Body className={styles.modalBody}>
                                <div className={styles.textGeneral}>Your payment was successfully processed! A reciept was sent to <span style={{ color: 'rgb(255, 111, 0)'}}>{clientInfo?.parentEmail}</span>. Press "Continue" to be redirected to the home page.</div>
                            </Modal.Body>
                            <Modal.Footer className='d-flex justify-content-center'>
                                <Link to="/">
                                    <Button variant="secondary">
                                        Continue
                                    </Button>
                                </Link>
                            </Modal.Footer>
                        </>
                    )}
                    {purchaseStatus === 'failure' && (
                        <>
                            <Modal.Body className={styles.modalBody}>
                                <div className={styles.textGeneral}>There was an error processing your payment. Please verify your information and try again.</div>
                            </Modal.Body>
                            <Modal.Footer className='d-flex justify-content-center'>
                                <Button variant="secondary" onClick={() => {
                                    setShow(false)
                                }}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                    {purchaseStatus === 'ready' && ( // No error messages were generated based on quickbooks error response, but there was indeed an error
                        <>
                            <Modal.Body className={styles.modalBody}>
                                <div className={styles.textGeneral}>There was an unidentified error processing your payment. Please verify your information and try again.</div>
                            </Modal.Body>
                            <Modal.Footer className='d-flex justify-content-center'>
                                <Button variant="secondary" onClick={() => {
                                    setShow(false)
                                }}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
                </Modal>
                <div className="d-flex flex-column align-items-center" style={{ marginTop: "100px", marginBottom: !vertical? "100px" : "30px" }}>
                    <div id="heading" className="d-flex justify-content-center">
                        <button onClick={() => setStage("information")} className={styles.stageButton + " " + (stage === "information" ? (styles.stageButtonSelected) : "")} disabled={approved.information ? false : true}>
                            <span className={styles.stageButtonText + " " + (stage === 'information' ? styles.stageTextSelected : "") + " " + (!approved.information ? styles.disabled : "")}>Customer Info</span>
                        </button>
                        <button onClick={() => setStage("paymentInfo")} className={styles.stageButton + " " + (stage === "paymentInfo" ? (styles.stageButtonSelected) : "")} disabled={approved.paymentInfo ? false : true}>
                            <span className={styles.stageButtonText + " " + (stage === 'paymentInfo' ? styles.stageTextSelected : "") + " " + (!approved.paymentInfo ? styles.disabled : "")}>Payment Info</span>
                        </button>
                        <button onClick={() => setStage("confirmation")} className={styles.stageButton + " " + (stage === "confirmation" ? (styles.stageButtonSelected) : "")} disabled={approved.confirmation ? false : true}>
                            <span className={styles.stageButtonText + " " + (stage === 'confirmation' ? styles.stageTextSelected : "") + " " + (!approved.confirmation ? styles.disabled : "")}>Confirmation</span>
                        </button>
                    </div>
                    <div id="main" style={{ marginTop: vertical ? "20px" : "46px", width: "100%" }}>
                        {stage === "information" && (
                            <div className="d-flex justify-content-center">
                                <div style={{ marginRight: !vertical && "32px", width: "100%", flex: "2" }}>
                                    <ClientInfoForm mobile={vertical} clientInfo={clientInfo} setClientInfo={setClientInfo} setStage={setStage} setApproved={setApproved} checkoutType={checkoutType} serverErrors={serverErrors} clearError={clearError}/>
                                </div>
                                {!vertical && (
                                    <div style={{ flex: "1" }}>
                                        {generateItemPreview()}
                                    </div>
                                )}
                            </div>
                        )}
                        {stage === "paymentInfo" && (
                            <div className="d-flex justify-content-center">
                                <div style={{ marginRight: !vertical && !vertical && "32px", width: "100%", flex: "2" }}>
                                    <PaymentForm mobile={vertical} paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} setStage={setStage} setApproved={setApproved} serverErrors={serverErrors} clearError={clearError}/>
                                </div>
                                {!vertical && (
                                    <div style={{ flex: "1" }}>
                                        {generateItemPreview()}
                                    </div>
                                )}
                            </div>
                        )}
                        {stage === "confirmation" && (
                            <>
                                {!vertical ? (
                                    <div className="d-flex justify-content-center">
                                        <div style={{ marginRight: !vertical && "32px", width: "100%", flex: "2" }}>
                                            <PayConfirmation clientInfo={clientInfo} paymentInfo={paymentInfo} item={item} checkoutType={checkoutType} mongoId={props?.id} serverErrors={serverErrors} setServerErrors={setServerErrors} setModalShow={setShow} purchaseStatus={purchaseStatus} setPurchaseStatus={setPurchaseStatus} setStage={setStage}/>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                            {generateItemPreview()}
                                        </div>
                                    </div>
                                ):(
                                    <div className="d-flex flex-column align-items-center">
                                        <div>
                                            {generateItemPreview()}
                                        </div>
                                        <div style={{ marginRight: !vertical && "32px", width: "100%" }}>
                                            <PayConfirmation mobile={vertical} clientInfo={clientInfo} paymentInfo={paymentInfo}  item={item} checkoutType={checkoutType} mongoId={props?.id} serverErrors={serverErrors} setServerErrors={setServerErrors} setModalShow={setShow} purchaseStatus={purchaseStatus} setPurchaseStatus={setPurchaseStatus} setStage={setStage}/>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div>
                Loading Page Content...
            </div>
        )
    }
}

export default Checkout