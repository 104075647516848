import React, { useState, useEffect } from 'react'
import CreditCard from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'react-credit-cards/es/styles-compiled.css'
import styles from './PaymentForm.module.css'
import checkoutStyles from '../Checkout.module.css'
import './PaymentForm.css'
import { getData } from 'country-list'
import { UsaStates } from 'usa-states'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { checkIsNumber } from '../../../utility/validation'
import CheckoutError from '../CheckoutError/CheckoutError'

const ERRORS_LENGTH = 4

const PaymentForm = ({ paymentInfo, setPaymentInfo, mobile, setStage, setApproved, serverErrors, clearError }) => {
    const [cvc, setCVC] = useState('')
    const [expiry, setExpiry] = useState({ month: '', year: '' })
    const [focus, setFocus] = useState('')
    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [issuer, setIssuer] = useState('')
    const [address, setAddress] = useState({ streetAddress: '', city: '', zipCode: '', region: '', country: 'US' })
    const [cardValid, setCardValid] = useState('')
    const [allValid, setAllValid] = useState(false)
    const [errors, setErrors] = useState([])

    let usStates = new UsaStates();

    useEffect(() => {
        if (paymentInfo) {
            setCVC(paymentInfo.cvc)
            setExpiry(paymentInfo.expiry)
            setName(paymentInfo.name)
            setNumber(paymentInfo.number)
            setAddress(paymentInfo.address)
        }
    }, [])

    useEffect(() => {
        // Only update the paymentInfo if all fields have been altered
        if (cvc !== '' && expiry.month !== '' && expiry.year !== '' && number !== '' && name !== '' && address.streetAddress !== '' && address.city !== '' && address.zipCode !== '' && address.region !== '' && address.country !== '') {
            handlePaymentSubmit()
        }

        // Frontend Validation
        let newErrors = errors.slice()

        if (cvc.length !== 3 && cvc.length !== 4 || !checkIsNumber(cvc)) {
            newErrors[0] = 'CVC is not Valid'
        } else {
            newErrors[0] = undefined
        }

        if (!cardValid) {
            newErrors[1] = 'Card Number is not Valid'
        } else {
            newErrors[1] = undefined
        }

        if (expiry.month.length < 2 || expiry.year.length < 2 || !checkIsNumber(expiry.month) || !checkIsNumber(expiry.year)) {
            newErrors[2] = 'Expiration Date is not Valid'
        } else {
            newErrors[2] = undefined
        }

        if (!checkFieldsFilled()) {
            newErrors[3] = 'Please Fill in all Fields'
        } else {
            newErrors[3] = undefined
        }

        setErrors(newErrors)
    }, [cvc, expiry, name, number, address, cardValid])

    useEffect(() => {
        checkAllValid()
    }, [errors])

    // Accounts for google autofill or copy and paste actions on expiry date input field
    useEffect(() => {
        if (expiry.year.length > 2) {
            expiry.year = expiry.year.substring(2, 4)
        }

        if (expiry.month.length > 2) {
            expiry.year = expiry.year.substring(0, 1)
        }
    }, [expiry])

    function handleSubmit(event) {
        event.preventDefault()

        handlePaymentSubmit()

        setStage('confirmation')
        setApproved({
            information: true,
            paymentInfo: true,
            confirmation: true
        })
    }

    function handlePaymentSubmit() {
        setPaymentInfo({
            issuer: issuer,
            number: number,
            cvc: cvc,
            expiry: expiry,
            name: name,
            address: {
                streetAddress: address.streetAddress,
                zipCode: address.zipCode,
                city: address.city,
                region: address.region,
                country: address.country
            }
        })
    }

    function checkFieldsFilled() {
        if (cvc !== '' && expiry.month !== '' && expiry.year !== '' && number !== '' && name !== '' && address.streetAddress !== '' && address.city !== '' && address.zipCode !== '' && address.region !== '' && address.country !== '') {
            return true
        }

        return false
    }

    function checkAllValid() {
        let valid = true

        for (let i = 0; i < ERRORS_LENGTH; i++) {
            if (errors[i] !== undefined) {
                valid = false
            }
        }

        if (valid) {
            setAllValid(true)
        } else {
            setAllValid(false)
        }
    }

    function renderFrontendErrors() {
        let error = undefined

        for (let i = 0; i < ERRORS_LENGTH; i++) {
            if (errors[i] !== undefined) {
                error = errors[i]
            }
        }

        return error
    }

    function renderStateSelection() {
        return (
            <>
                {address.country === '' && (
                    <div/>
                )}
                {address.country === 'US' && (
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.region && (
                            <div className={checkoutStyles.errorHolder} style={{ right: '20px' }}>
                                <CheckoutError message={serverErrors.region} />
                            </div>
                        )}
                        <Form.Control
                            as="select" 
                            required
                            value={address.region}
                            onFocus={() => clearError('region')}
                            onChange={(event) => {
                                let newAddress = { ...address }
                                newAddress.region = event.target.value
                                setAddress(newAddress)
                            }}
                            className={styles.inputField + " " + (serverErrors?.region && checkoutStyles.fieldError) + " dropDownArrow"}
                        >
                            <option value='' disabled>State</option>
                            {usStates.states.map((state, i) => {
                                return (
                                    <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
                                )
                            })}
                        </Form.Control>
                    </div>
                )}
                {address.country !== '' && address.country !== 'US' && (
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.region && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.region} />
                            </div>
                        )}
                        <Form.Control
                            required
                            type="text"
                            autoComplete='shipping region'
                            placeholder="Region"
                            value={address.region}
                            onFocus={() => clearError('region')}
                            onChange={(event) => {
                                let newAddress = { ...address }
                                newAddress.region = event.target.value
                                setAddress(newAddress)
                            }}
                            className={styles.inputField + " " + (serverErrors?.region && checkoutStyles.fieldError)}
                        />
                    </div>
                )}
            </>
        )
    }

    // Returns a string that represents the full expiration date, created from expiration month and year
    function setDateValue() {
        if ((expiry.month.length >= 2 && expiry.year.length >= 2) || expiry.month.length >= 2) {
            return expiry.month + " / " + expiry.year
        } else if (expiry.month.length < 2) {
            return expiry.month
        }
    }

    return (
        <div>
            <div style={{ marginBottom: "24px" }} className="customCreditCard">
                <CreditCard
                callback={(type, valid) => { // component defined callback function that has a built in type and valid parameter
                    setIssuer(type.issuer)
                    setCardValid(valid)
                }}
                cvc={cvc}
                expiry={expiry.month + "/" + expiry.year}
                focused={focus}
                name={name}
                number={number}
                placeholders={{ name: 'Cardholder Name' }}
                />
            </div>
            <div className={styles.dividingLine}/>
            <Form noValidate onSubmit={handleSubmit} className="w-100">
                <Form.Group controlId="cardNumber">
                    <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                        <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Card Number</Form.Label>
                        <div className={checkoutStyles.inputFieldHolder}>
                            {serverErrors?.cardNumber && (
                                <div className={checkoutStyles.errorHolder}>
                                    <CheckoutError message={serverErrors.cardNumber} />
                                </div>
                            )}
                            <Form.Control
                                required
                                type="text"
                                autoComplete='cc-number'
                                placeholder="Card #"
                                name='cardNumber'
                                maxLength='19'
                                className={styles.inputField + " " + (serverErrors?.cardNumber && checkoutStyles.fieldError)}
                                value={number}
                                onChange={(event) => setNumber(event.target.value)}
                                onFocus={(event) => {
                                    clearError('cardNumber')
                                    setFocus(event.target.name)
                                }}
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group controlId="validExpiry">
                    <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                        <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Expiration Date</Form.Label>
                        <div className={checkoutStyles.inputFieldHolder} style={{ marginBottom: mobile && "16px" }}>
                            {serverErrors?.expiration && (
                                <div className={checkoutStyles.errorHolder}>
                                    <CheckoutError message={serverErrors.expiration} />
                                </div>
                            )}
                            <Form.Control
                                required
                                type="text"
                                autoComplete='off'
                                placeholder="MM / YY"
                                name="expiration"
                                className={styles.inputField + " " + (serverErrors?.expiration && checkoutStyles.fieldError)}
                                value={setDateValue()}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace' && expiry.year.length <= 0 && expiry.month.length >= 2) {
                                        event.preventDefault()
                                        setExpiry({
                                            month: expiry.month.charAt(0),
                                            year: ''
                                        })
                                    }
                                }}
                                onChange={(event) => {
                                    let strings = event.target.value.split('/')

                                    if (strings[1]) {
                                        setExpiry({
                                            month: strings[0].trim(),
                                            year: strings[1].trim()
                                        })
                                    } else {
                                        setExpiry({
                                            month: strings[0].trim(),
                                            year: ''
                                        })
                                    }
                                }}
                                onFocus={(event) => {
                                    clearError('expiration')
                                    setFocus(event.target.name)
                                }}
                                maxLength="7"
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group controlId="validCVC">
                    <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                        <Form.Label className={styles.label} style={{ flexShrink: "0" }}>CVC</Form.Label>
                        <div className={checkoutStyles.inputFieldHolder}>
                            {serverErrors?.cvc && (
                                <div className={checkoutStyles.errorHolder}>
                                    <CheckoutError message={serverErrors.cvc} />
                                </div>
                            )}
                            <Form.Control
                                required
                                type="text"
                                autoComplete='cc-csc'
                                placeholder="CVC"
                                name="cvc"
                                onFocus={(event) => {
                                    clearError('cvc')
                                    setFocus(event.target.name)
                                }}
                                className={styles.inputField + " " + (serverErrors?.cvc && checkoutStyles.fieldError)}
                                value={cvc}
                                onChange={(event) => setCVC(event.target.value)}
                                maxLength="4"
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group controlId="validName">
                    <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                        <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Cardholder Name</Form.Label>
                        <div className={checkoutStyles.inputFieldHolder}>
                            {serverErrors?.cardName && (
                                <div className={checkoutStyles.errorHolder}>
                                    <CheckoutError message={serverErrors.cardName} />
                                </div>
                            )}
                            <Form.Control
                                required
                                type="text"
                                autoComplete='cc-name'
                                placeholder="Name"
                                name="name"
                                onFocus={(event) => {
                                    clearError('cardName')
                                    setFocus(event.target.name)
                                }}
                                className={styles.inputField + " " + (serverErrors?.cardName && checkoutStyles.fieldError)}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group controlId="validAddress">
                    <div className={`d-flex ${!mobile ? "align-items-start" : "flex-column"}`} style={{ width: '100%' }}>
                        <Form.Label className={styles.label + " " + styles.billingLabel} style={{ flexShrink: "0" }}>Billing Address</Form.Label>
                        <div className="d-flex flex-column w-100">
                            {!mobile ? (
                                <>
                                    <div className="d-flex" style={{ marginBottom: "16px", width: '100%' }}>
                                        <div className={checkoutStyles.inputFieldHolder} style={{ marginRight: "12px" }}>
                                            {serverErrors?.streetAddress && (
                                                <div className={checkoutStyles.errorHolder}>
                                                    <CheckoutError message={serverErrors.streetAddress} />
                                                </div>
                                            )}
                                            <Form.Control
                                                required
                                                type="text"
                                                autoComplete='shipping street-address'
                                                placeholder="Street Address"
                                                value={address.streetAddress}
                                                onFocus={() => clearError('streetAddress')}
                                                onChange={(event) => {
                                                    let newAddress = { ...address }
                                                    newAddress.streetAddress = event.target.value
                                                    setAddress(newAddress)
                                                }}
                                                className={styles.inputField + " " + (serverErrors?.streetAddress && checkoutStyles.fieldError)}
                                            />
                                        </div>
                                        <div className={checkoutStyles.inputFieldHolder}>
                                            {serverErrors?.city && (
                                                <div className={checkoutStyles.errorHolder}>
                                                    <CheckoutError message={serverErrors.city} />
                                                </div>
                                            )}
                                            <Form.Control
                                                required
                                                type="text"
                                                autoComplete='shipping locality'
                                                placeholder="City"
                                                value={address.city}
                                                onFocus={() => clearError('city')}
                                                onChange={(event) => {
                                                    let newAddress = { ...address }
                                                    newAddress.city = event.target.value
                                                    setAddress(newAddress)
                                                }}
                                                className={styles.inputField + " " + (serverErrors?.city && checkoutStyles.fieldError)}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{ marginBottom: address.country !== '' && "16px", width: '100%' }}>
                                        <div className={checkoutStyles.inputFieldHolder} style={{ marginRight: "12px" }}>
                                            {serverErrors?.zipCode && (
                                                <div className={checkoutStyles.errorHolder}>
                                                    <CheckoutError message={serverErrors.zipCode} />
                                                </div>
                                            )}
                                            <Form.Control
                                                required
                                                type="text"
                                                autoComplete='shipping postal-code'
                                                placeholder="Postal Code"
                                                value={address.zipCode}
                                                onFocus={() => clearError('zipCode')}
                                                onChange={(event) => {
                                                    let newAddress = { ...address }
                                                    newAddress.zipCode = event.target.value
                                                    setAddress(newAddress)
                                                }}
                                                className={styles.inputField + " " + (serverErrors?.zipCode && checkoutStyles.fieldError)}
                                            />
                                        </div>
                                        <div className={checkoutStyles.inputFieldHolder}>
                                            {serverErrors?.country && (
                                                <div className={checkoutStyles.errorHolder} style={{ right: "20px" }}>
                                                    <CheckoutError message={serverErrors.country} />
                                                </div>
                                            )}
                                            <Form.Control
                                                as="select" 
                                                required
                                                value={address.country}
                                                onFocus={() => clearError('country')}
                                                onChange={(event) => {
                                                    let newAddress = { ...address }
                                                    newAddress.country = event.target.value
                                                    setAddress(newAddress)
                                                }}
                                                className={styles.inputField + " " + (serverErrors?.country && checkoutStyles.fieldError) + " dropDownArrow"}
                                            >
                                                {getData().map((country, i) => {
                                                    return (
                                                        <option key={country.code} value={country.code}>{country.name}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </div>
                                    </div>
                                    {renderStateSelection()}
                                </>
                            ):(
                                <>
                                    <div className={checkoutStyles.inputFieldHolder} style={{ marginBottom: "16px" }}>
                                        {serverErrors?.streetAddress && (
                                            <div className={checkoutStyles.errorHolder}>
                                                <CheckoutError message={serverErrors.streetAddress} />
                                            </div>
                                        )}
                                        <Form.Control
                                            required
                                            type="text"
                                            autoComplete='shipping street-address'
                                            placeholder="Street Address"
                                            value={address.streetAddress}
                                            onFocus={() => clearError('streetAddress')}
                                            onChange={(event) => {
                                                let newAddress = { ...address }
                                                newAddress.streetAddress = event.target.value
                                                setAddress(newAddress)
                                            }}
                                            className={styles.inputField + " " + (serverErrors?.streetAddress && checkoutStyles.fieldError)}
                                        />
                                    </div>
                                    <div className={checkoutStyles.inputFieldHolder} style={{ marginBottom: "16px" }}>
                                        {serverErrors?.city && (
                                            <div className={checkoutStyles.errorHolder}>
                                                <CheckoutError message={serverErrors.city} />
                                            </div>
                                        )}
                                        <Form.Control
                                            required
                                            type="text"
                                            autoComplete='shipping locality'
                                            placeholder="City"
                                            value={address.city}
                                            onFocus={() => clearError('city')}
                                            onChange={(event) => {
                                                let newAddress = { ...address }
                                                newAddress.city = event.target.value
                                                setAddress(newAddress)
                                            }}
                                            className={styles.inputField + " " + (serverErrors?.city && checkoutStyles.fieldError)}
                                        />
                                    </div>
                                    <div className={checkoutStyles.inputFieldHolder} style={{ marginBottom: "16px" }}>
                                        {serverErrors?.zipCode && (
                                            <div className={checkoutStyles.errorHolder}>
                                                <CheckoutError message={serverErrors.zipCode} />
                                            </div>
                                        )}
                                        <Form.Control
                                            required
                                            type="text"
                                            autoComplete='shipping postal-code'
                                            placeholder="Postal Code"
                                            value={address.zipCode}
                                            onFocus={() => clearError('zipCode')}
                                            onChange={(event) => {
                                                let newAddress = { ...address }
                                                newAddress.zipCode = event.target.value
                                                setAddress(newAddress)
                                            }}
                                            className={styles.inputField + " " + (serverErrors?.zipCode && checkoutStyles.fieldError)}
                                        />
                                    </div>
                                    <div className={checkoutStyles.inputFieldHolder} style={{ marginBottom: address.country !== '' && "16px" }}>
                                        {serverErrors?.country && (
                                            <div className={checkoutStyles.errorHolder} style={{ right: "20px" }}>
                                                <CheckoutError message={serverErrors.country} />
                                            </div>
                                        )}
                                        <Form.Control
                                            as="select" 
                                            required
                                            value={address.country}
                                            onFocus={() => clearError('country')}
                                            onChange={(event) => {
                                                let newAddress = { ...address }
                                                newAddress.country = event.target.value
                                                setAddress(newAddress)
                                            }}
                                            className={styles.inputField + " " + (serverErrors?.country && checkoutStyles.fieldError) + " dropDownArrow"}
                                        >
                                            <option value='' disabled>Country</option>
                                            {getData().map((country, i) => {
                                                return (
                                                    <option key={country.code} value={country.code}>{country.name}</option>
                                                )
                                            })}
                                        </Form.Control>
                                    </div>
                                    {renderStateSelection()}
                                </>
                            )}
                        </div>
                    </div>
                </Form.Group>
                <div className="w-100 d-flex justify-content-between">
                    <Button onClick={() => setStage('information')} className={checkoutStyles.submitButton}>
                        Back
                    </Button>
                    {!allValid ? (
                        <OverlayTrigger className="bcp-tooltip" placement='left' overlay={
                            <Tooltip id="tooltip-disabled" className="bcp-tooltip">
                                {renderFrontendErrors()}
                            </Tooltip>
                        }>
                            <div>
                                <Button type="submit" className={checkoutStyles.submitButton} style={{ pointerEvents: allValid ? '' : 'none' }} disabled={allValid ? false : true}>
                                    Continue
                                </Button>
                            </div>
                        </OverlayTrigger>
                    ):(
                        <Button type="submit" className={checkoutStyles.submitButton}>
                            Continue
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    )
}

export default PaymentForm