import React, {useState, useEffect} from 'react'
import styles from './FileInput.module.css'
import './FileInput.css'
import Files from 'react-files'
import { FaTimesCircle, FaFilePdf, FaFile, FaFileImage } from 'react-icons/fa';

const FileTest = ({ files, setFiles }) => {
    const [errorFlag, setError] = useState(false);

    useEffect(() => {
        if (errorFlag) {
            setTimeout(setError(false), 1000)
        }
    }, [errorFlag]);

    const onFilesChange = (inputFiles) => {
        if(!errorFlag) {
            let newList = files.slice(0);
            newList[newList.length] = inputFiles[inputFiles.length - 1];
            setFiles(newList);
        }
    }

    const handleDelete = (i) => {
        let newList = files.slice(0);
        newList.splice(i, 1);
        setFiles(newList)
    }

    function renderFileIcon(item) {
        if (item.extension === 'pdf') {
            return (
                <FaFilePdf style={{ color: "#808080", fontSize: '24px', marginRight: '10px' }}/>
            )
        } else if (item.extension === 'png' || item.extension === 'jpg' || item.extension === 'jpeg') {
            return (
                <FaFileImage style={{ color: "#808080", fontSize: '24px', marginRight: '10px' }}/>
            )
        } else {
            return (
                <FaFile style={{ color: "#808080", fontSize: '24px', marginRight: '10px' }}/>
            )
        }
    }
    
    return(
    <>
      <div className="files">
        <Files
          className={`${styles.dropzone} files-dropzone d-flex align-items-center justify-content-center`}
          onChange={onFilesChange}
          onError={() => setError(true)}
          accepts={['.gif', '.png', '.pdf', '.txt', '.jpg', '.jpeg', '.docx', '.zip', '.csv', '.doc', '.docm', '.rar', '.rtf', '.pptx']}
          multiple
          maxFiles={10}
          maxFileSize={5000000} // 5 Megabytes
          clickable
        >
          Drop Files Here or Click to Upload
        </Files>
      </div>
      <div className="d-flex flex-column align-items-center">
          {files.map((item, i) => {
              return(
                  <div key={i} className={`${styles.uploadedFileContainer} d-flex justify-content-between align-items-center`}>
                    <div className="d-flex align-items-center">
                        {renderFileIcon(item)}
                        <p style={{marginBottom: '0px'}}>{item.name}</p>
                    </div>
                    <a className={`${styles.delete}`} onClick={()=>{handleDelete(i)}}>
                        <FaTimesCircle style={{ fontSize: '20px' }} />
                    </a>
                  </div>
              )
          })}
      </div>
    </>
    )
}

export default FileTest;