import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import styles from './ClientInfoForm.module.css'
import checkoutStyles from '../Checkout.module.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { validateEmail, validatePhoneNumber, isFullName } from '../../../utility/validation'
import FileInput from '../../FileInput/FileInput'
import CheckoutError from '../CheckoutError/CheckoutError'
import { getSchools } from '../../../api/api'

const ERRORS_LENGTH = 7 // Needs to be updated if new fields with frontend validation are added

const ClientForm = ({ clientInfo, setClientInfo, mobile, setStage, setApproved, checkoutType, serverErrors, clearError }) => {
    const [studentName, setStudentName] = useState('')
    const [studentPhone, setStudentPhone] = useState('')
    const [studentEmail, setStudentEmail] = useState('')
    const [school, setSchool] = useState('')
    const [otherSchool, setOtherSchool] = useState(false)
    const [schoolList, setSchoolList] = useState([])
    const [grade, setGrade] = useState('')
    const [parentName, setParentName] = useState('')
    const [parentPhone, setParentPhone] = useState('')
    const [parentEmail, setParentEmail] = useState('')
    const [location, setLocation] = useState('')
    const [files, setFiles] = useState([])
    const [allValid, setAllValid] = useState(false)
    const [errors, setErrors] = useState([])

    // Reload states from client info (set in general checkout component) if a user returns to client form section
    // Fetch list of schools from api (for school dropdown)
    useEffect(() => {
        if (clientInfo) {
            setStudentName(clientInfo.studentName)
            setStudentPhone(clientInfo.studentPhone)
            setStudentEmail(clientInfo.studentEmail)
            setSchool(clientInfo.school)
            setGrade(clientInfo.gradeLevel)
            setParentName(clientInfo.parentName)
            setParentPhone(clientInfo.parentPhone)
            setParentEmail(clientInfo.parentEmail)
            setLocation(clientInfo.location)
            setOtherSchool(clientInfo.otherSchool)
            setFiles(clientInfo.files)
        }

        getSchools()
            .then(res => setSchoolList(res.data))
            .catch(err => console.log('Failed to Load List of Schools'))
    }, [])

    // Update error list when info states change. Update client info (general checkout state) when info states change
    useEffect(() => {
        handleSubmitInfo()

        // Frontend Validation
        let newErrors = errors.slice()

        if (!validateEmail(studentEmail)) {
            newErrors[0] = 'Student Email is not a Valid'
        } else {
            newErrors[0] = undefined
        }

        if (!validateEmail(parentEmail)) {
            newErrors[1] = 'Parent Email is not a Valid'
        } else {
            newErrors[1] = undefined
        }

        if (!validatePhoneNumber(studentPhone)) {
            newErrors[2] = 'Student Phone Number is not Valid'
        } else {
            newErrors[2] = undefined
        }

        if (!validatePhoneNumber(parentPhone)) {
            newErrors[3] = 'Parent Phone Number is not Valid'
        } else {
            newErrors[3] = undefined
        }

        if (!isFullName(studentName)) {
            newErrors[4] = 'Full Student Name Must be Provided'
        } else {
            newErrors[4] = undefined
        }

        if (!isFullName(parentName)) {
            newErrors[5] = 'Full Parent Name Must be Provided'
        } else {
            newErrors[5] = undefined
        }

        if (!checkFieldsFilled()) {
            newErrors[6] = 'Please Fill in all Fields'
        } else {
            newErrors[6] = undefined
        }

        setErrors(newErrors)
    }, [studentName, studentPhone, studentEmail, school, grade, parentName, parentPhone, parentEmail, location])

    // Checks if all fields are valid whenever errors is updated
    useEffect(() => {
        checkAllValid()
        console.log('Errors: ', errors)
    }, [errors])

    // Updates client info state in general checkout component and moves user to next checkout section
    function handleSubmit(event) {
        event.preventDefault()
        
        handleSubmitInfo()

        setStage('paymentInfo')

        setApproved({
            information: true,
            paymentInfo: true,
            confirmation: false
        })
    }

    function handleSubmitInfo() {
        setClientInfo({
            studentName: studentName,
            studentPhone: studentPhone,
            studentEmail: studentEmail,
            school: school,
            otherSchool: otherSchool,
            gradeLevel: grade,
            parentName: parentName,
            parentEmail: parentEmail,
            parentPhone: parentPhone,
            location: location,
            files: files
        })
    }

    function checkFieldsFilled() {
        if (studentName !== '' && studentPhone !== '' && studentEmail !== '' && school !== '' && grade !== '' && parentName !== '' && parentPhone !== '' && parentEmail !== '' && location !== '') {
            return true
        }

        return false
    }

    function checkAllValid() {
        let valid = true

        for (let i = 0; i < ERRORS_LENGTH; i++) {
            if (errors[i] !== undefined) {
                valid = false
            }
        }

        if (valid) {
            setAllValid(true)
        } else {
            setAllValid(false)
        }
    }

    // Generates error message for 'continue' button tooltip
    function renderFrontendErrors() {
        let error = undefined

        for (let i = 0; i < ERRORS_LENGTH; i++) {
            if (errors[i] !== undefined) {
                error = errors[i]
            }
        }

        return error
    }

    return (
        <Form noValidate onSubmit={(event) => handleSubmit(event)} className="w-100">
            <Form.Group controlId="validStudentName">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Student Name</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.studentName && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.studentName} />
                            </div>
                        )}
                        <Form.Control
                            required
                            type="text"
                            placeholder="Student Name"
                            onFocus={() => clearError('studentName')}
                            value={studentName}
                            onChange={(event) => setStudentName(event.target.value)}
                            className={styles.inputField + " " + (serverErrors?.studentName && checkoutStyles.fieldError)}
                        />
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validStudentPhone">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Student Phone #</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.studentPhone && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.studentPhone} />
                            </div>
                        )}
                        <Form.Control
                            required
                            type="tel"
                            placeholder="Student Phone #"
                            onFocus={() => clearError('studentPhone')}
                            value={studentPhone}
                            onChange={(event) => setStudentPhone(event.target.value)}
                            className={styles.inputField + " " + (serverErrors?.studentPhone && checkoutStyles.fieldError)}
                        />
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validStudentEmail">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Student Email</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.studentEmail && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.studentEmail} />
                            </div>
                        )}
                        <Form.Control
                            required
                            type="email"
                            placeholder="Student Email"
                            onFocus={() => clearError('studentEmail')}
                            value={studentEmail}
                            onChange={(event) => setStudentEmail(event.target.value)}
                            className={styles.inputField + " " + (serverErrors?.studentEmail && checkoutStyles.fieldError)}
                        />
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validSchool">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>School</Form.Label>
                    <div className={styles.schoolFields}>
                        <div className={checkoutStyles.inputFieldHolder}>
                            {serverErrors?.school && (
                                <div className={checkoutStyles.errorHolder}>
                                    <CheckoutError message={serverErrors.school} />
                                </div>
                            )}
                            <Form.Control
                                as="select"
                                required
                                onFocus={() => clearError('school')}
                                value={otherSchool ? 'other' : school}
                                onChange={(event) => {
                                    if (event.target.value == 'other') {
                                        setOtherSchool(true)
                                        setSchool('')
                                    } else {
                                        setSchool(event.target.value)
                                        setOtherSchool(false)
                                    }
                                }}
                                className={styles.inputField + " " + (serverErrors?.school ? checkoutStyles.fieldError : "dropDownArrow")}
                            >
                                <option value='' disabled>School</option>
                                {schoolList.map((school, i) => {
                                    return (
                                        <option key={school.name + ' ' + i} value={school.name}>{school.name}</option>
                                    )
                                })}
                                <option value='other'>Other</option>
                            </Form.Control>
                        </div>
                        { otherSchool && (
                            <div className={checkoutStyles.inputFieldHolder} style={{ marginTop: '4px' }}>
                                {serverErrors?.school && (
                                    <div className={checkoutStyles.errorHolder}>
                                        <CheckoutError message={serverErrors.school} />
                                    </div>
                                )}
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="School"
                                    onFocus={() => clearError('school')}
                                    value={school}
                                    onChange={(event) => setSchool(event.target.value)}
                                    className={styles.inputField + " " + (serverErrors?.school && checkoutStyles.fieldError)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="formGridState">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Grade Level</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.gradeLevel && (
                            <div className={checkoutStyles.errorHolder} style={{ right: "20px" }}>
                                <CheckoutError message={serverErrors.gradeLevel} />
                            </div>
                        )}
                        <Form.Control
                        as="select" 
                        required
                        onFocus={() => clearError('gradeLevel')}
                        value={grade}
                        onChange={(event) => setGrade(event.target.value)}
                        className={styles.inputField + " " + (serverErrors?.gradeLevel ? checkoutStyles.fieldError : "dropDownArrow")}>
                            <option value='' disabled>Student Grade Level</option>
                            <option value='9'>9th Grade (Freshman)</option>
                            <option value='10'>10th Grade (Sophomore)</option>
                            <option value='11'>11th Grade (Junior)</option>
                            <option value='12'>12th Grade (Senior)</option>
                        </Form.Control>
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validParent">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Parent Name</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.parentName && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.parentName} />
                            </div>
                        )}
                        <Form.Control
                            required
                            type="text"
                            placeholder="Parent Name"
                            onFocus={() => clearError('parentName')}
                            value={parentName}
                            onChange={(event) => setParentName(event.target.value)}
                            className={styles.inputField + " " + (serverErrors?.parentName && checkoutStyles.fieldError)}
                        />
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validParentPhone">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Parent Phone #</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.parentPhone && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.parentPhone} />
                            </div>
                        )}
                            <Form.Control
                                required
                                type="tel"
                                placeholder="Parent Phone #"
                                onFocus={() => clearError('parentPhone')}
                                value={parentPhone}
                                onChange={(event) => setParentPhone(event.target.value)}
                                className={styles.inputField + " " + (serverErrors?.parentPhone && checkoutStyles.fieldError)}
                            />
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validParentEmail">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Parent Email</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.parentEmail && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.parentEmail} />
                            </div>
                        )}
                            <Form.Control
                                required
                                type="email"
                                placeholder="Parent Email"
                                onFocus={() => clearError('parentEmail')}
                                value={parentEmail}
                                onChange={(event) => setParentEmail(event.target.value)}
                                className={styles.inputField + " " + (serverErrors?.parentEmail && checkoutStyles.fieldError)}
                            />
                    </div>
                </div>
            </Form.Group>
            <Form.Group controlId="validLocation">
                <div className={`d-flex ${!mobile ? "align-items-center" : "flex-column"}`}>
                    <Form.Label className={styles.label} style={{ flexShrink: "0" }}>Location</Form.Label>
                    <div className={checkoutStyles.inputFieldHolder}>
                        {serverErrors?.location && (
                            <div className={checkoutStyles.errorHolder}>
                                <CheckoutError message={serverErrors.location} />
                            </div>
                        )}
                            <Form.Control
                                as="select"
                                required
                                onFocus={() => clearError('location')}
                                value={location}
                                onChange={(event) => setLocation(event.target.value)}
                                className={styles.inputField + " " + (serverErrors?.location ? checkoutStyles.fieldError : "dropDownArrow")}
                            >
                                <option value='' disabled>Location</option>
                                <option value='In-Person'>Office (In-Person)</option>
                                <option value='Zoom'>Zoom (Online)</option>
                            </Form.Control>
                    </div>
                </div>
            </Form.Group>
            {checkoutType === 'session' && (
                <Form.Group>
                    <div className="d-flex flex-column">
                        <div style={{ marginBottom: "8px" }}>
                            <Form.Label className={styles.label} style={{ width: "unset" }}>
                                It is recommended that you provide the following documents prior to the consultation: <span style={{ color: "#FF2E00" }}>Resume, Transcripts, Essays, Testing History, Medical Conditions</span>
                            </Form.Label>
                        </div>
                        <div className={styles.smallBackground}>
                            <FileInput files={files} setFiles={setFiles}/>
                        </div>
                    </div>
                </Form.Group>
            )}
            <div className="w-100 d-flex justify-content-end">
                {!allValid ? (
                    <OverlayTrigger className="bcp-tooltip" placement='left' overlay={
                        <Tooltip id="tooltip-disabled" className="bcp-tooltip"> 
                            {renderFrontendErrors()}
                        </Tooltip>
                    }>
                        <div>
                            <Button type="submit" className={checkoutStyles.submitButton} style={{ pointerEvents: allValid ? '' : 'none' }} disabled={allValid ? false : true}>
                                Continue
                            </Button>
                        </div>
                    </OverlayTrigger>
                ):(
                    <Button type="submit" className={checkoutStyles.submitButton}>
                        Continue
                    </Button>
                )}
            </div>
        </Form>
    )
}

export default ClientForm