import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import checkoutStyles from '../Checkout.module.css'
import styles from './PayConfirmation.module.css'
import { tokenizeCard, createSessionPayment, createCoursePayment, saveSessionFiles } from '../../../api/api'
import { getName } from 'country-list'
import { capitalizeFirstLetter } from '../../../utility/general'
import { FaFilePdf, FaFile, FaFileImage } from 'react-icons/fa';
import CheckoutError from '../CheckoutError/CheckoutError'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { handleError } from './QBErrorHandler'

const PayConfirmation = ({ clientInfo, paymentInfo, setStage, mongoId, item, checkoutType, serverErrors, setServerErrors, setModalShow, purchaseStatus, setPurchaseStatus }) => {
    useEffect(() => {
        if (purchaseStatus === 'loading') {
            setModalShow(true)
        }
    }, [purchaseStatus])
    
    function handlePurchase() {
        // This will disable the purchase button
        setPurchaseStatus('loading')

        // Post credit card data to quick books, push client info to database if the charge is valid
        const card = {
            "currency": "USD", 
            "amount": item.price, 
            "context": {
                "mobile": "false", 
                "isEcommerce": "true"
            }, 
            "card": {
                "name": paymentInfo.name, 
                "number": paymentInfo.number, 
                "expMonth": paymentInfo.expiry.month, 
                "address": {
                    "postalCode": paymentInfo.address.zipCode, 
                    "country": paymentInfo.address.country, 
                    "region": paymentInfo.address.region, 
                    "streetAddress": paymentInfo.address.streetAddress, 
                    "city": paymentInfo.address.city
                },
                "expYear": "20" + paymentInfo.expiry.year, 
                "cvc": paymentInfo.cvc
            }
        }

        tokenizeCard(card).then(res => {
            let { files, ...info } = clientInfo
            
            let paymentData = {
                tokenValue: res.value,
                _id: mongoId,
                customerName: paymentInfo.name,
                emailAddress: info.parentEmail,
                customerInfo: info
            }

            // Course
            if (checkoutType === 'course') {
                createCoursePayment(paymentData)
                    .then(payRes => {
                        console.log("This response: ", payRes)
                        setPurchaseStatus('success')
                    })
                    .catch(err => {
                        console.log("Course Payment Error: ", err.response.data)

                        if (err.response.data.status === 500) { // Payment Validation Error
                            if (err.response.data.message?.errors || typeof err.response.data.message == "string") {
                                handlePaymentErrors(err.response.data.message.errors || err.response.data.message)
                                setPurchaseStatus('failure')
                            } else if (typeof err.response.data.message?.status == "string" && err.response.data.message?.status.toUpperCase() === 'DECLINED') { // Payment failed Error
                                setPurchaseStatus('ready')
                            } else {
                                setPurchaseStatus('ready')
                            }
                        } else if (err.response.data.status === 400) { // Client Info Validation Error
                            handleInfoErrors(err.response.data.data)
                            setPurchaseStatus('failure')
                        }
                    })
            } else { // Session - TODO: Update error handling, refer to course error handling
                let fileData = new FormData();

                files.forEach(file => {
                    fileData.append('files', file);
                })
                
                paymentData.times = item.selectedTimes;

                createSessionPayment(paymentData)
                    .then(payRes => {
                        let { purchasedId, ...rest } = payRes.data;
                        setPurchaseStatus('success')

                        saveSessionFiles(paymentData._id, purchasedId, fileData)
                            .then(filesRes => {
                                console.log(filesRes);
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    })
                    .catch(err => {
                        console.log("Session Payment Error: ", err.response.data)

                        if (err.response.data.status === 500) { // Payment Error
                            handlePaymentErrors(err.response.data.message.errors)
                        } else if (err.response.data.status === 400) { // Client Info Error
                            handleInfoErrors(err.response.data.data)
                        }
                        setPurchaseStatus('failure')
                    })
            }
        })
        .catch((err) => {
            console.log('Tokenize Card Error: ', err.response.data)

            handlePaymentErrors(err.response.data.errors)
            setPurchaseStatus('failure')
        })
    }

    // Set server errors state for validation errors passed from backend
    function handleInfoErrors(errors) {
        let temp = {}

        if (errors) {
            errors.forEach((error) => {
                let key = error.param.slice(error.param.lastIndexOf('.') + 1)
    
                // Account for backend validation errors that won't concern the user
                if (!(key === 'emailAddress' || key === 'customerInfo' || key === 'customerName')) {
                    temp[key] = error.msg
                }
            })
    
            setServerErrors({
                ...serverErrors,
                ...temp
            })
        } else {
            setPurchaseStatus('ready')
        }
        
    }

    // Set server errors state for errors passed from quickbooks/inuit
    function handlePaymentErrors(errors) {
        let temp = {}

        if (errors) {
            errors.forEach((error) => {
                const errorResult = handleError(error)
    
                if (errorResult) {
                    temp[errorResult.key] = errorResult.message
                }
            })
    
            setServerErrors({
                ...serverErrors,
                ...temp
            })
        } else {
            setPurchaseStatus('ready')
        }
    }

    // Renders appropriate file icon for files of different types
    function renderFileIcon(item) {
        if (item.extension === 'pdf') {
            return (
                <FaFilePdf style={{ color: "#808080", fontSize: '24px', marginRight: '10px' }}/>
            )
        } else if (item.extension === 'png' || item.extension === 'jpg' || item.extension === 'jpeg') {
            return (
                <FaFileImage style={{ color: "#808080", fontSize: '24px', marginRight: '10px' }}/>
            )
        } else {
            return (
                <FaFile style={{ color: "#808080", fontSize: '24px', marginRight: '10px' }}/>
            )
        }
    }

    // If there is an error with an of the fields in the conditional below, a general card error is triggered. Specifics can be seen by the user upon revisiting pay form page
    function checkCardError() {
        if (serverErrors) {
            if (serverErrors.cardNumber || serverErrors.cardName || serverErrors.cvc || serverErrors.expiration) {
                return true
            }
        }

        return false
    }

    // If there is an error with an of the fields in the conditional below, a general address error is triggered. Specifics can be seen by the user upon revisiting pay form page
    function checkAddressError() {
        if (serverErrors) {
            if (serverErrors.streetAddress || serverErrors.city || serverErrors.zipCode || serverErrors.country || serverErrors.region) {
                return true
            }
        }

        return false
    }

    return (
        <div>
            { (clientInfo && paymentInfo) ? (
                <>
                    <h3 className={styles.heading}>Client Information</h3>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.studentName && styles.errorContainer)}>
                        {serverErrors?.studentName && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.studentName} />
                            </div>
                        )}
                        <div className={styles.label}>Student Name:</div>
                        <div>{clientInfo.studentName}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.studentPhone && styles.errorContainer)}>
                        {serverErrors?.studentPhone && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.studentPhone} />
                            </div>
                        )}
                        <div className={styles.label}>Student Phone #:</div>
                        <div>{clientInfo.studentPhone}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.studentEmail && styles.errorContainer)}>
                        {serverErrors?.studentEmail && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.studentEmail} />
                            </div>
                        )}
                        <div className={styles.label}>Student Email:</div>
                        <div>{clientInfo.studentEmail}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.school && styles.errorContainer)}>
                        {serverErrors?.school && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.school} />
                            </div>
                        )}
                        <div className={styles.label}>School:</div>
                        <div>{clientInfo.school}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.gradeLevel && styles.errorContainer)}>
                        {serverErrors?.gradeLevel && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.gradeLevel} />
                            </div>
                        )}
                        <div className={styles.label}>Grade Level:</div>
                        <div>{clientInfo.gradeLevel}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.parentName && styles.errorContainer)}>
                        {serverErrors?.parentName && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.parentName} />
                            </div>
                        )}
                        <div className={styles.label}>Parent Name:</div>
                        <div>{clientInfo.parentName}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.parentEmail && styles.errorContainer)}>
                        {serverErrors?.parentEmail && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.parentEmail} />
                            </div>
                        )}
                        <div className={styles.label}>Parent Email:</div>
                        <div>{clientInfo.parentEmail}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.parentPhone && styles.errorContainer)}>
                        {serverErrors?.parentPhone && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.parentPhone} />
                            </div>
                        )}
                        <div className={styles.label}>Parent Phone #:</div>
                        <div>{clientInfo.parentPhone}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (serverErrors?.location && styles.errorContainer)}>
                        {serverErrors?.location && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.location} />
                            </div>
                        )}
                        <div className={styles.label}>Location:</div>
                        <div>{clientInfo.location}</div>
                    </div>
                    {(checkoutType === 'session' && clientInfo.files.length > 0) && (
                        <div className={styles.fieldContainer} style={{ display: 'block' }}>
                            <div style={{ marginBottom: "4px" }}>
                                <label className={styles.label} style={{ width: "unset" }}>
                                    Submitted Files:
                                </label>
                            </div>
                            <div>
                                {clientInfo.files.map((file, i) => {
                                    return (
                                        <div key={i} className={`${styles.uploadedFileContainer} d-flex justify-content-between align-items-center`}>
                                            <div className="d-flex align-items-center">
                                                {renderFileIcon(file)}
                                                <p style={{marginBottom: '0px'}}>{file.name}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    <div className={styles.dividingLine}/>
                    <h3 className={styles.heading}>Payment Information</h3>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (checkCardError() && styles.errorContainer)}>
                        {checkCardError() && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.cardNumber || serverErrors.cvc || serverErrors.cardName || serverErrors.expiryMonth || serverErrors.expiryYear} />
                            </div>
                        )}
                        <div className={styles.label}>Card:</div>
                        <div>{capitalizeFirstLetter(paymentInfo.issuer)} ending in {paymentInfo.number.slice(-4)}</div>
                    </div>
                    <div className={styles.textGeneral + " " + styles.fieldContainer + " " + (checkAddressError() && styles.errorContainer)} style={{ marginBottom: "0px" }}>
                        {checkAddressError() && (
                            <div className={checkoutStyles.errorHolder + " " + styles.errorHolder}>
                                <CheckoutError message={serverErrors.streetAddress || serverErrors.city || serverErrors.country || serverErrors.region || serverErrors.zipCode} />
                            </div>
                        )}
                        <div className={styles.label} style={{ flexShrink: '0' }}>Billing Address:</div>
                        <div className={styles.addressBody}>{paymentInfo.address.streetAddress}, {paymentInfo.address.city}, {paymentInfo.address.zipCode}, {paymentInfo.address.region}, {paymentInfo.address ? getName(paymentInfo.address.country) : undefined}</div>
                    </div>
                    <div className={styles.dividingLine}/>
                    <div className="w-100 d-flex justify-content-between">
                        <Button onClick={() => setStage('paymentInfo')} className={checkoutStyles.submitButton}>
                            Back
                        </Button>
                        {!(purchaseStatus === 'ready') ? (
                            <OverlayTrigger className="bcp-tooltip" placement='left' overlay={
                                <Tooltip id="tooltip-disabled" className="bcp-tooltip">
                                    Please fix the indicated errors
                                </Tooltip>
                            }>
                                <div>
                                    <Button type="submit" className={checkoutStyles.submitButton} style={{ pointerEvents: purchaseStatus === 'ready' ? '' : 'none' }} disabled={purchaseStatus === 'ready' ? false : true}>
                                        Finalize Order
                                    </Button>
                                </div>
                            </OverlayTrigger>
                        ):(
                            <Button type="submit" onClick={handlePurchase} className={checkoutStyles.submitButton}>
                                Finalize Order
                            </Button>
                        )}
                    </div>
                </>
            ):(
                <p style={{ color: "rgb(210, 32, 39)" }}>Error: Missing Information</p>
            )}
        </div>
    )
}

export default PayConfirmation