import React from 'react'
import styles from './ItemPreview.module.css'

const ItemPreview = ({ item }) => {
    return (
        <div className={`${styles.smallBackground} d-flex flex-column align-items-center`} style={{ marginBottom: "20px" }}>
            <h3 className={styles.headerText}>{item.title}</h3>
            {item.image && (
                <img src={item.image} alt="course" className={styles.image}/>
            )}
            <div className={styles.dividingLine}/>
            {item.description && (
                <p style={{ marginTop: "12px", width: "100%", textAlign: "left", flexGrow: "0" }}>{item.description}</p>
            )}
            <div style={{ width: "100%", marginTop: "1rem" }}>
                <span className={styles.label}>Price: ${item.price}</span>
            </div>
        </div>
    )
}

export default ItemPreview