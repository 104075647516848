import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import styles from './CheckoutError.module.css'
import { FaExclamationCircle } from 'react-icons/fa'

const CheckoutError = ({ message }) => {
    return (
        <OverlayTrigger className="bcp-tooltip" overlay={
            <Tooltip id="tooltip-disabled" className="bcp-tooltip">
                {message || 'There was an error with this field'}
            </Tooltip>
        }>
            <FaExclamationCircle className={styles.icon}/>
        </OverlayTrigger>
    )
}

export default CheckoutError