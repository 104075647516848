import React from "react"
import { Link } from 'gatsby'
import { Router } from "@reach/router"
import "../styles/global.css"
import Checkout from "../components/Checkout/Checkout"
import CheckoutLayout from '../layouts/CheckoutLayout'
import styles from '../styles/checkout.module.css'
import SEO from '../components/SEO'

const CheckoutPage = () => {
    return (
        <div>
            <SEO title="Checkout"/>
            <CheckoutLayout>
                <Router basepath="/checkout">
                    <Checkout path="/course/:id" />
                    <Checkout path="/session/:id" />
                    <NotFound default />
                </Router>
            </CheckoutLayout>
        </div>
    )
}

const NotFound = () => {
    return (
        <div className={`${styles.notFoundContainer} d-flex justify-content-center align-items-center`}>
            <p className={styles.generalText}>It seems you've lost your way. <Link to="/" className={styles.link}>Go home</Link>.</p>
        </div>
    )
}

export default CheckoutPage