import React from 'react'
import Navbar from "react-bootstrap/Navbar"
import styles from "./MinimalNavbar.module.css"
import BeckLogoSvg from "../../../images/beck-logo.svg"
import { Link } from "gatsby"

const BCPNavbar = () => {
    return (
        <header>
            <Navbar id="navbar" expand="lg" fixed="top" className={styles.navbar}>
                <Navbar.Brand as={Link} to="/" className={`${styles.navbarBrand}`}>
                    <img
                    src={BeckLogoSvg}
                    width="24px"
                    height="auto"
                    style={{ marginRight: "10px" }}
                    className={`d-inline-block align-middle ${styles.navbarLogo}`}
                    alt="Beck College Prep Logo"
                    />
                    <div className={`${styles.brandTextContainer}`}>
                        <span className={`${styles.brandTextHeader}`}>Beck College Prep</span>
                        <span className={`${styles.brandTextSubheader}`}>We Motivate You</span>
                    </div>
                </Navbar.Brand>
            </Navbar>
        </header>
    );
  };

  export default BCPNavbar;